import React from 'react'
import styled from 'styled-components'

const WidthContainer = styled.div`
	width: 100%;
	max-width: ${props => props.width ? props.width : '1200'}px;
	margin: 0 auto;
`

const Container = props => {
	return(
		<WidthContainer width={props.width}>
			{props.children}
		</WidthContainer>
	)
}

export default Container
