import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faMapPin } from '@fortawesome/fontawesome-free-solid'
import { faFacebookSquare } from '@fortawesome/fontawesome-free-brands'

import Container from '../../components/container'

const Border = styled.section`
	background: #171717;
`

const Flex = styled.div`
	display: ${props => props.display ? props.display : 'flex'};
	justify-content: space-between;
	padding: 20px 0;
	align-items: center;
	text-align: center;
`

const Text = styled.p`
	color: white;
	a {
		color: ${props => props.color ? props.color: 'White'};
		&:hover {
			color: Crimson;
		}
	}
`

const Icon = styled.p`
	color: white;
	font-size: 40px;
	margin: 0 20px;
	a {
		color: ${props => props.color ? props.color: 'White'};
		&:hover {
			color: Crimson;
		}
	}
`

const Footer = () => {

	return(
		<Border>
			<Container>
				<Flex>
					<div>
						<Text>
							<a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/26+%26+Park+Le+Barber/@44.9560811,-93.2666287,17z/data=!3m1!4b1!4m5!3m4!1s0x52b333b972af1cfd:0x6f3f1bddb7867893!8m2!3d44.9560811!4d-93.26444">
								2615 Park Ave b6, Minneapolis, MN 55407<FontAwesomeIcon icon={faMapPin} />
							</a>
						</Text>
						<Text>
							<a href="tel:612-879-2615" target='_top'>(612) 879-2615 <FontAwesomeIcon icon={faPhone} /></a>
						</Text>
					</div>
					<Icon>
						<a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/pages/category/Barber-Shop/26-Park-Le-Barber-2166544123584033/"><FontAwesomeIcon icon={faFacebookSquare} /></a>
					</Icon>
					<div>
						<Text>©2020 26 Park Le Barber.</Text>
					</div>
				</Flex>
			</Container>
		</Border>
		
	)
}

export default Footer
