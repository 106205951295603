// Libraries
import React from 'react'
import styled from 'styled-components'

// Components

const Nav = styled.header`
	position: fixed;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	z-index: 1;
`

const NavItems = styled.div`
	width: calc(100% - 40px);
	max-width: calc(1200px - 40px);
	margin: 0 auto;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0 20px;
`

const NavLink = styled.p`
	padding: 20px;
	color: white;
	font-size: 20px;
	cursor: pointer;
	&:hover {
		color: LightSteelBlue;
	}
`

const NavLinkA = styled.a`
	padding: 20px;
	color: white;
	font-size: 20px;
	cursor: pointer;
	&:hover {
		color: LightSteelBlue;
	}
`

const nav = () => {
	return(
		<Nav>
			<NavItems>
				<NavLink onClick={() => window.scrollTo(0, 0)}>
					Home
				</NavLink>
				<NavLinkA href="https://brett-petersen.square.site/">
					Book Now!
				</NavLinkA>
				<NavLink onClick={() => window.scrollTo(0, window.innerHeight)}>
					Map
				</NavLink>
			</NavItems>
			
		</Nav>
	)
}

export default nav