// Libraries
import React from 'react'
import styled from 'styled-components'

// Components
import Hero from '../hero'
import Map from '../map'

const InformationContainer = styled.section`
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	position: relative;
`



const Information = () => {
	return(
		<InformationContainer>
			<Hero />
			<Map />
		</InformationContainer>
	)
}

export default Information