// Libraries
import React from 'react'
import styled from 'styled-components'

// Components
import Logo from '../logo'

// Resources
import Image from './hero.jpg'

const Hero = styled.section`
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	position: relative;
	background: black;
	display: flex;
	justify-content: center;
	height: 800px;
`

const HeroImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
	
`

const LogoContainer = styled.figure`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
    justify-content: center;
	width: 100%;
	height: 100%;
	margin: 0;
	background: rgba(0,0,0,.3);
	flex-direction: column;
`

const Book = styled.a`
	color: white;
	border: 5px solid white;
	cursor: pointer;
	margin-top: 80px;
	font-size: 30px;
	text-decoration: none;
	padding: 10px 30px;
	border-radius: 10px;
	font-weight: 700;
	&:hover {
		background: darkgray;
	}
`

const Panel = styled.article`
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1;
	background: rgba(0,0,0,.75);
	color: white;
	padding: 10px 20px;
`

const Flex = styled.div`
	display: flex;
	align-items: center;
	padding: 10px 0;
`

const H3 = styled.h3`
	margin: 0 10px 0 0;
`

const P = styled.p`
	margin: 0;
`

const hero = () => {
	return(
		<Hero>
			<HeroImage alt="hero" src={Image} />
			<LogoContainer>
				<Logo />
				<Book href="https://brett-petersen.square.site/">BOOK NOW!</Book>
			</LogoContainer>
			<Panel>
				<Flex>
					<H3>Address:</H3>
					<P>2615 Park Ave b6, Minneapolis, MN 55407</P>
				</Flex>
				<Flex>
					<H3>Phone:</H3>
					<P>(612) 879-2615</P>
				</Flex>
				<Flex>
					<H3>Hours:</H3>
					<P>Saturdays, 08:00am - 03:00pm</P>
				</Flex>
			</Panel>
		</Hero>
	)
}

export default hero