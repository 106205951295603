import React from 'react'
import styled from 'styled-components'

const Logo = () => {

    const Flex = styled.div`
	  display: flex;
	  justify-content: center;
	  height: 56px;
	  margin-bottom: 20px;
	  @media only screen and (min-width: 1024px) {
		height: 87px;
	  }
	`
	const Left = styled.h1`
	  font-size: 45pt;
	  margin: 0;
	  color: white;
	  @media only screen and (min-width: 1024px) {
		font-size: 90pt;
	  }
	`
	const Right = styled.h1`
	  font-family: Valentine;
	  font-size: 20pt;
	  line-height: 74pt;
	  margin: 0 0 0 5px;
	  color: white;
	  @media only screen and (min-width: 1024px) {
		font-size: 40pt;
	  	line-height: 148pt;
	  }
	`

    return (
		<Flex>
			<Left>26</Left>
			<Right>Park Le Barber</Right>
		</Flex>
    )
}

export default Logo
