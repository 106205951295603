// Libraries
import React from 'react'
import styled from 'styled-components'

// Components
import Nav from '../components/nav'
import Information from '../components/information'
import Footer from '../components/footer'

const Root = styled.section`
  background: black;
  width: 100%;
`

function App() {
  return (
    <Root>
      <Nav />
      <Information />
      {/* <Contact /> */}
      {/* <Footer /> */}
      <Footer />
    </Root>
  )
}

export default App
